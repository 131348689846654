@use "sass:color";
@import "../../styles/variables";

.gallery-header {
  display: flex;
  flex-direction: row;
  flex: 0 0 40px;
  align-items: center;

  .avatar {
    flex: 0 0 32px;
    height: 32px;
    margin-right: 0.5em;

    [dir="rtl"] & {
      margin-left: 0.5em;
      margin-right: 0;
    }
  }
}

.gallery-label {
  flex-grow: 1;
  font-size: 1.5em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.gallery-street-count {
  font-size: 1.5em;
  color: rgb(128 128 128);
  white-space: nowrap;
}

.gallery-streets-container {
  display: flex;
  flex-direction: row;
}

// Applied by the wrapping element created by Scrollable.jsx component
.streets-scrollable-container {
  position: relative;
  top: 1px;
  height: $gallery-height;
  min-width: 0; // Allows content to overflow flex element
  flex-grow: 1;

  button.scrollable {
    margin-top: 45px;
    width: 30px;

    &.scroll-left {
      left: -10px !important;
    }

    &.scroll-right {
      right: -10px !important;
    }
  }

  .streets {
    list-style: none;
    padding: 0;
    margin: 0;
    white-space: nowrap;
    height: $gallery-height + 20px; // To cover the scroll bar
    overflow-x: scroll;
  }
}

.gallery-user-buttons {
  display: flex;
  flex: 0 0 100px;
  flex-direction: column;
  height: 110px;
  margin-top: 6px;
  margin-right: 16px;

  a.btn,
  button {
    padding-left: 0.5em;
    padding-right: 0.5em;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
  }

  [dir="rtl"] & {
    margin-left: 16px;
    margin-right: 0;
  }
}

.gallery-new-street {
  flex-grow: 1;
  font-weight: bold;
}

.gallery-copy-last-street {
  height: 40px;
  flex-basis: 40px;
  margin-top: 10px;
}
