@import "../../styles/variables";

.debug-info {
  position: fixed;
  inset: 0;
  padding: 30px;
  z-index: $z-09-debug;
  background-color: rgb(192 192 192 / 50%);

  > textarea {
    width: 100%;
    height: 100%;
    border: 1px solid lightgray;
    background-color: whitesmoke;
    font-family: monospace;
    resize: none;
    box-shadow: 0 0 0 10px white;
  }
}
