@use "sass:color";
@import "../../styles/variables";
@import "../../styles/mixins";
@import "../streets/StreetName";

$welcome-panel-border-radius: $border-radius;
$welcome-panel-box-shadow: $medium-box-shadow;

.welcome-panel-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: $z-05-welcome;
  text-align: center;
  user-select: none;
  pointer-events: none;
}

.welcome-panel {
  display: inline-block;
  position: relative;
  padding: 0.5em 1.5em 0.75em;
  margin: 0;
  background: white;
  text-align: center;
  font-size: 1.2rem;
  color: rgb(64 64 64);
  pointer-events: auto;
  border-bottom-left-radius: $welcome-panel-border-radius;
  border-bottom-right-radius: $welcome-panel-border-radius;
  box-shadow: $welcome-panel-box-shadow;
}

.welcome-panel-content {
  h1 {
    @include large-message-text;

    margin-top: 0.25em;
    margin-bottom: 0.25em;
  }

  button {
    margin: 0 0.25em;
    padding: 0.5em 1em;
  }

  ul,
  p,
  .paragraph {
    display: inline-block;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 5px;
  }

  p,
  .paragraph {
    line-height: 1.5;
    display: block;
    max-width: 700px;
  }

  li {
    display: inline-block;
    padding: 0;
    margin: 0;

    &:not(:first-child)::before {
      content: "·";
      margin: 0 5px;
      color: color.adjust($ui-colour, $lightness: -40%);
      padding-left: 0;
    }
  }

  input[type="radio"] {
    display: none;
    border: 0;
    background: none;
    padding: 0;
    margin: 0;
  }

  label {
    cursor: pointer;

    &:hover {
      color: black;
    }
  }

  input[type="radio"]:checked + label {
    color: black;
    font-weight: bold;
  }

  input[type="radio"]:not(:checked) + label {
    text-decoration: underline;
  }
}

// Component specific tweaks to StreetName
// Some duped styles from gallery element
.welcome-panel .street-name {
  @include street-name-mixin($street-name-gallery-size);

  display: inline-block;
  position: relative;
  top: 5px;
  max-width: 100%;
  margin: 0 0.25em;

  > div,
  > span {
    @include street-name-inside-mixin($street-name-gallery-size);
  }
}
