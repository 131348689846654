@import "../../styles/variables";

$dirt-height: 40px;
$dirt-height-minimum: 5px;

.street-section-dirt {
  position: absolute;
  left: 0;
  right: 0;
  top: $canvas-height - 80px + $dirt-height;
  border-top: $dirt-height-minimum solid $background-dirt-colour;
  background: $bottom-background;
  z-index: -2;
  pointer-events: none;
}

.street-section-dirt-left,
.street-section-dirt-right {
  content: " ";
  position: absolute;
  top: -($dirt-height + $dirt-height-minimum);
  background-color: $background-dirt-colour;
  width: 360px;
  height: $dirt-height;
}

.street-section-dirt-left {
  left: 0;
}

.street-section-dirt-right {
  right: 0;
}
