@import "../../styles/variables";

$palette-height: 64px;

.palette-container-outer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: $palette-height;
  padding-left: $left-right-inset;
  padding-right: $left-right-inset;
  display: flex;
  justify-content: center;
}

.palette-container {
  position: relative;
  width: 100%;
  min-width: $breakpoint-md;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: $palette-background;
  z-index: $z-02-palette;
  user-select: none;
  touch-action: none;
  border-top-left-radius: $border-radius-large;
  border-top-right-radius: $border-radius-large;
}

@media only screen and (max-width: $breakpoint-md) {
  .palette-container-outer {
    padding-left: 0;
    padding-right: 0;
  }

  .palette-container {
    left: 0;
    right: 0;
    border-radius: 0;
    min-width: 100%;
  }
}

.palette-commands {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1em;
  width: 120px;

  button {
    height: 40px;
    width: 40px;
    padding: 0;
  }

  // Spacing between buttons
  button + button {
    margin-left: 0.25em;

    // Other way around on rtl
    [dir="rtl"] & {
      margin-left: 0;
      margin-right: 0.25em;
    }
  }
}

.palette-commands-right {
  justify-content: flex-end;
}
