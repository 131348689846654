@use "sass:color";
@import "../../styles/variables";

$environment-editor-border-radius: $border-radius-large;
$environment-editor-box-shadow: $medium-box-shadow;
$header-background-colour: $colour-turquoise-200;
$header-text-colour: $colour-turquoise-700;

.environment-editor-container-outer {
  z-index: $z-07-environment-editor;
  position: absolute;

  // TODO: use button trigger to set initial position,
  // and remember position on a per-session basis
  bottom: 90px;
  left: 50px;
  max-width: 216px; /* prevent upgrade text from expanding width */
}

.environment-editor-container-inner {
  background-color: white;
  border-radius: $environment-editor-border-radius;
  overflow: hidden;
  box-shadow: $environment-editor-box-shadow;
  user-select: none;

  [dir="rtl"] & {
    left: auto;
    right: 50px;
  }
}

.environment-editor header {
  padding: 0.5em;
  background-color: $header-background-colour;
  color: $header-text-colour;
  display: flex;
  align-items: center;
}

.environment-editor.react-draggable header {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
}

.environment-editor.react-draggable-dragging header {
  cursor: grabbing;
}

.environment-editor header img {
  height: 16px;
  width: auto;
  margin-left: 0.5em;
  pointer-events: none;

  [dir="rtl"] & {
    margin-right: 0.5em;
    margin-left: auto;
  }
}

.environment-editor header h3 {
  flex: 1;
  min-width: 0;
  margin: 0 0.25em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1em;

  .octicon {
    margin-right: 0.5em;

    [dir="rtl"] & {
      margin-left: 0.5em;
      margin-right: auto;
    }
  }
}

.environment-editor-content {
  padding: 0;
}

.environment-upgrade-box {
  padding: 0.75em 1em;
  background-color: $alert-background-light;
  font-size: 1rem;
  line-height: 1.2;
  text-align: center;
  color: $colour-copper-700;

  /* Very similar to .toast-action style */
  button {
    display: block !important;
    border: 1px solid $colour-copper-400;
    border-radius: $border-radius-medium;
    padding: 0.5em 1em;
    margin: 0.75em auto 0.25em;
    width: auto;
    font-weight: bold;
    background-color: transparent;
    color: $colour-copper-600;

    &:disabled {
      border: 1px solid color.adjust($colour-copper-200, $saturation: -50%);
    }

    &:hover {
      background-color: transparent;
      color: $colour-copper-700;
    }
  }
}

/* Close button overrides */
.environment-editor .close {
  position: relative;
  width: 24px;
  height: 24px;
  padding: 0;
  top: auto;
  right: auto;
  display: flex !important;
  justify-content: center;
  align-items: center !important;
}
