@use "sass:color";
@import "../../styles/variables";

.sign-in-dialog {
  width: 450px;
  min-height: 250px;
}

.sign-in-loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sign-in-email-label {
  display: block;
  font-weight: 700;
  font-size: 1.2em;
  margin-bottom: 0.25em;
}

.sign-in-input {
  border-radius: 3px;
  padding: 0.75em;
  width: 100%;
  border: $button-border;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 7%);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s;

  &:focus {
    border-color: $colour-turquoise-425;
    outline: 0;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 7%),
      0 0 8px color.adjust($colour-turquoise-450, $alpha: -0.1);
  }

  &.sign-in-input-error {
    border: 1px solid red !important;
  }
}

.sign-in-email-password-note {
  color: rgb(120 120 120); /* todo: standardize small colors */
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  line-height: 1.3;
}

.sign-in-loading-message {
  text-align: center;
  font-weight: normal;
  font-size: 1.25em;
}

.sign-in-social-heading {
  display: flex;
  margin-top: 1em;
  font-size: 1.25em;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  span {
    display: inline-block;
    background-color: white;
    position: absolute;
    padding: 1em;
  }
}

.sign-in-button {
  position: relative;
  width: 100%;
  display: flex !important;
  align-items: center !important;
}

.sign-in-social-button {
  text-align: left;

  /* Space for icon */
  padding-left: 54px !important;

  svg,
  img {
    position: absolute;
    width: 27px;
    height: 27px;
    left: 15px;
  }
}

.sign-in-twitter-button .fa-twitter {
  color: $social-twitter !important;
}

.sign-in-facebook-button .fa-square-facebook {
  color: $social-facebook !important;
}

.sign-in-disclaimer {
  text-align: center;
  margin-top: 2em;
  margin-left: 5em;
  margin-right: 5em;
}

.sign-in-email-sent {
  text-align: center;
  margin: 3em 2em 2em;
}

.sign-in-email {
  font-weight: 700;
}

.sign-in-resend {
  margin-top: 2em;
}

.sign-in-error-message {
  color: red;
  font-weight: bold;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}

.sign-in-email-button {
  justify-content: center;
}
