.feature-flag-dialog {
  width: 600px;
}

.feature-flag-dialog ul {
  padding: 0;
  list-style-type: none;
  column-count: 2;
  column-gap: 2em;
}

.feature-flag-dialog ul li {
  margin: 0;
  margin-bottom: 0.25em;
}

.feature-flag-dialog p {
  text-align: center;
}

.feature-flag-label-modified {
  font-weight: bold;
}
