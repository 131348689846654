@import "../../styles/variables";

$selection-highlight-colour: $colour-copper-500;
$selection-focus-colour: $colour-turquoise-500;

.environment-selector {
  display: grid;
  height: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-flow: row;
  grid-gap: 0.75em;
  padding: 1em;
}

.environment-item {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: $border-radius-large;
  cursor: pointer;
  transition: 120ms box-shadow;

  // These are functionally buttons, reset appearance
  appearance: none;
  border: 0;

  &:focus {
    box-shadow: 0 0 0 2px $selection-focus-colour,
      inset 0 0 0 2px rgb(255 255 255 / 100%);
  }
}

.environment-item.environment-active {
  box-shadow: 0 0 0 2px $selection-highlight-colour,
    inset 0 0 0 2px rgb(255 255 255 / 100%);
}

.environment-item.environment-disabled {
  cursor: default;

  .environment-disabled-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: $border-radius-large;
    background-color: rgba(221 221 221 / 65%);
  }

  .fa-lock {
    position: absolute;
    right: 4px;
    top: 4px;
    z-index: 2;
    color: $colour-copper-600;

    [dir="rtl"] & {
      left: 4px;
      right: auto;
    }
  }
}
