.info-bubble-remove {
  width: auto !important; /* Override button width of other buttons in infobubble */
  padding: 0;
  padding-left: 0.75em;
  padding-right: 0.75em;
  margin-top: 1px;
  margin-left: 1em;
  font-size: 11px;

  .remove-icon {
    width: 14px;
    height: 14px;
    margin-right: 0.5em;

    [dir="rtl"] & {
      margin-right: 0;
      margin-left: 0.5em;
    }
  }
}
