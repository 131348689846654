@import "../../styles/variables";

body.segment-resize-dragging,
body.segment-resize-dragging * {
  cursor: col-resize !important;
}

.drag-handle {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 320px;
  width: 30px;
  height: 100px;
  border-top: 5px solid $colour-turquoise-200;
  border-bottom: 5px solid $colour-turquoise-200;
  border-radius: $border-radius-medium;
  font-size: 1.5rem;
  color: $colour-turquoise-500;
  z-index: $z-06-drag-handle;
  pointer-events: none;
  background: $info-bubble-background;
  cursor: col-resize;
  transition: transform 150ms, opacity 150ms;
  opacity: 0;

  &:hover {
    color: $colour-turquoise-600;
  }
}

.drag-handle-left {
  left: -5px;
  text-align: left;
  text-indent: -5px;
  transform-origin: 100% 50%;
  transform: rotateY(90deg);
}

.drag-handle-right {
  right: -5px;
  text-align: right;
  text-indent: -3px;
  transform-origin: 0 50%;
  transform: rotateY(-90deg);
}

.segment.immediate-show-drag-handles .drag-handle {
  transition: none !important;
}

.drag-handle.floating {
  background-color: $colour-turquoise-500;
}

body.segment-resize-dragging .drag-handle.floating,
.segment.show-drag-handles .drag-handle {
  pointer-events: auto;
  opacity: 1;
  transform: none;
}
