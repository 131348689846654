@import "../../styles/variables";

.environment-badge {
  z-index: $z-02-menu-bar;
  display: none;
  position: absolute;
  left: 14px;
  bottom: -12px;
  padding: 0.25em 1em;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.5em;
  padding-right: 0.5em; // Right padding minus letter-spacing
  color: white;
  background-color: gray;
  pointer-events: none;

  &.environment-label-development {
    display: inline-block;
    background-color: rgb(200 0 0);
  }

  &.environment-label-staging,
  &.environment-label-sandbox {
    display: inline-block;
    background-color: green;
  }

  &.environment-label-demo {
    display: inline-block;
    background-color: rgb(28 189 209);
  }
}

[dir="rtl"] {
  .environment-badge {
    right: 16px;
    left: auto;
  }
}
