@use "sass:color";
@import "../../styles/variables";

.street-width {
  display: inline-flex;
  align-items: center;
}

.street-width.street-width-editable {
  cursor: pointer;
  text-decoration: underline;

  &:hover,
  &:hover * {
    color: color.adjust($ui-colour, $lightness: -40%);
  }

  svg {
    transform: scale(0.85);
  }
}

.street-width-over {
  margin-left: 0.25em;
  color: $warning-colour;

  [dir="rtl"] & {
    margin-right: 0.25em;
    margin-left: 0;
  }
}

.street-width-under {
  margin-left: 0.25em;
  color: rgb(96 96 96);
  transition: color $environment-transition;

  [dir="rtl"] & {
    margin-right: 0.25em;
    margin-left: 0;
  }
}

// Invert the UI text color when there is a dark background
body.dark-environs-invert-ui {
  .street-width-under {
    color: #a0a0a0;
  }
}
