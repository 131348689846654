@import "../../styles/variables";

.notification-bar {
  position: relative;
  z-index: $z-08-notification-bar;
  padding: 0.75em calc(36px + 1.5em); /* Makes room for dismiss button */
  text-align: center; /* Since the app is very center-view oriented, this helps message legibility */
  background-color: $alert-background;
  border-bottom: $alert-border;
}

/* Close button overrides */
// TODO: Make standard for toast, status, etc
$close-icon-colour: $colour-copper-500;
$close-icon-colour-hover: $colour-copper-600;

.notification-bar .close {
  width: 24px;
  height: 24px;
  padding: 0;
  top: 7px;
  right: 6px;
  display: flex !important;
  justify-content: center;
  align-items: center !important;

  &:hover {
    background-color: rgb(255 255 255 / 50%);
  }

  &:active {
    background-color: rgb(255 255 255 / 75%);
  }

  svg {
    color: $close-icon-colour !important;
  }

  &:hover svg {
    color: $close-icon-colour-hover !important;
  }
}
