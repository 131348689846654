@import "../../styles/variables";

.menus-container {
  position: absolute;
  inset: 0 $menu-side-inset;
  pointer-events: none;
  z-index: $z-07-menu;

  // Required in Firefox to get perspective to work on menus
  transform-style: preserve-3d;
}
