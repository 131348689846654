@import "../../styles/variables";
@import "../../styles/mixins";

$menu-border-radius: $border-radius;
$menu-box-shadow: $medium-box-shadow;

.menu {
  position: absolute;
  margin-top: 10px;
  touch-action: none;
  opacity: 0;
  background: white;
  transform-origin: 50% 0;
  transform: rotateX(-90deg);
  transition: transform 100ms, opacity 100ms;
  pointer-events: none;
  border-radius: $menu-border-radius;
  box-shadow: $menu-box-shadow;
  line-height: 1.4;

  /* Allows scrolling if the menu length is longer than height of viewport */
  max-height: calc(100% - 100px);
  overflow-x: hidden;
  overflow-y: auto;

  &.menu-visible-enter-done {
    transform: rotateX(0);
    opacity: 1;
    pointer-events: auto;
  }

  &.menu-visible-exit {
    transform: rotateX(-90deg);
    opacity: 0;
  }

  > a {
    display: block;
    position: relative;
    padding: 0.8em 2.5em;
    border-bottom: 1px solid $colour-turquoise-200;
    text-decoration: none;
    color: black;

    &:last-child {
      border-bottom: 0;
    }

    &:hover {
      background-color: $colour-turquoise-100;
    }

    &:active {
      background-color: $colour-turquoise-150;
    }
  }

  input {
    appearance: none;
    background: $form-element-background;
    border: 1px solid $form-element-border;
    resize: none;
  }

  .menu-item-icon {
    position: absolute;
    top: 0.8em;
    left: 0.8em;
    width: 16px;
    height: 16px;
    color: $colour-midnight-600;

    [dir="rtl"] & {
      left: auto;
      right: 0.8em;
    }
  }

  /* Radix icons are optimized for 15px */
  .menu-item-icon-radix {
    position: absolute;
    top: 0.9em;
    left: 0.8em;
    width: 15px;
    height: 15px;
    color: $colour-midnight-600;

    [dir="rtl"] & {
      left: auto;
      right: 0.8em;
    }
  }

  .menu-item-external-link {
    vertical-align: text-top;
    margin-left: 0.25em;
    color: $colour-midnight-400;
  }
}

body.safari .menu {
  transform: translateY(20px) translateZ(100px);

  &.menu-visible-enter-done {
    /* Y-position of 0 puts it too close to menubar, so give it a bit of space */
    transform: translateY(3px) rotateX(0) translateZ(100px);
  }
}

.menu-item-group {
  list-style: none;
  padding: 0;
  margin: 0;
  border-top: 1px solid $colour-turquoise-200;
  border-bottom: 1px solid $colour-turquoise-200;

  // Remove the top border if menu group is the first element in a menu
  &:first-child {
    border-top: 0;
  }

  // Remove the bottom border if menu group is the last element in a menu
  &:last-child {
    border-bottom: 0;
  }

  // Remove the doubling up of borders between consecutive menu groups
  + .menu-item-group {
    border-top: 0;
  }
}

.menu-item {
  display: block;
  position: relative;
  padding: 0.5em 2.5em;
  cursor: pointer;

  &:hover {
    background-color: $colour-turquoise-100;
  }

  .fa-check {
    display: none;
    left: 1.25em;
    margin-top: -1px;
    width: 12px;
    height: 12px;
    color: #1c99ce;

    [dir="rtl"] & {
      left: auto;
      right: 1.25em;
    }
  }

  &.menu-item-selected .fa-check {
    display: inline-block;
  }

  .loading-spinner {
    position: absolute;
    left: 16px;
    top: 8px;

    [dir="rtl"] & {
      left: auto;
      right: 16px;
    }
  }
}

.menu-item-subtext {
  display: block;
  margin-top: 4px;
  margin-bottom: 4px;
  line-height: 8px;
  color: rgb(128 128 128);
  font-size: 0.8em;
}

.menu-item-icon {
  &.fa-facebook,
  &.fa-square-facebook {
    color: $social-facebook;
  }

  &.fa-twitter {
    color: $social-twitter;
  }

  &.fa-discord {
    color: $social-discord;
  }

  &.fa-github {
    color: $social-github;
  }

  &.fa-mastodon {
    color: $social-mastodon;
  }
}
