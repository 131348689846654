.credits-team {
  display: grid;

  /* Setting a min-width value of 0 prevents the grid columns from changing
     its width if child content is too large, like a super long word */
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-auto-flow: row;
  grid-gap: 1.5em;
}

.credits-team-past {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 1.25em;
}

.credits-container {
  display: flex;
  margin-top: 1.5em;

  > div {
    flex-grow: 1;
  }

  h4 {
    margin-top: 1em;
    margin-bottom: 0.25em;
    color: gray;
  }

  i {
    color: gray;
    font-style: normal;
  }
}
