@use "sass:color";
@import "../../styles/variables";

.segment.segment-in-palette {
  display: inline-block;
  position: relative;
  margin: 14px 0;
  height: 80px;
  width: 60px;
  top: 0;
  background-color: fade-out($sky-colour, 0.3);
  margin-right: 1px;
  transform-origin: 50% 75%;
  transition: transform 50ms !important;
}

/* Wrapper element for Tooltip */
.segment.segment-in-palette > div {
  height: 100%; // Must provide this for proper tooltip positioning
  width: 100%;
  text-align: center;
}

.segment.segment-in-palette:not(.segment-disabled):hover {
  background: fade-out($sky-colour, 0.05);
  z-index: 1;

  .segment-image {
    transform: scale(1.15);
    transform-origin: 50% 75px;
  }
}

.segment.segment-in-palette .segment-image {
  top: 0;
}

.segment.segment-disabled {
  background: color.adjust(fade-out($sky-colour, 0.3), $saturation: -100%);

  .segment-image {
    filter: grayscale(0.8);
    opacity: 0.5;
  }

  .fa-lock {
    position: absolute;
    right: 4px;
    top: 4px;
    z-index: 2;
    color: $colour-copper-600;

    [dir="rtl"] & {
      left: 4px;
      right: auto;
    }
  }
}
